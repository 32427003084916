.card {
	overflow: visible;
	border: 0;
	background: none;
	z-index: 900;

	.card-body {
		padding-top: 2rem;
		.card-title,
		.card-subtitle,
		.card-title-link {
			color: $green-darker;
			font-weight: 600;
		}
		.card-title,
		.card-title-link {
			font-size: 20px;
		}
		.card-subtitle {
			font-family: $font-family-primary;
			font-size: 14px;
			color: $black;
		}

		.card-btn {
			background: none;
			min-width: auto;
			padding: 0;
			border: 0;
			color: $blue-dark;
			text-decoration: underline;
			box-shadow: none;
		}
	}

	&:hover {
		z-index: 990;
	}
	.card-image,
	.card-image img {
		transform: scale(1.05);
	}
	.card-image img {
		border-radius: 40px;
		border: 3px solid $white;
	}
}

.grid-items {
	.item {
		flex: 25% !important;
		max-width: 25% !important;
		padding-right: 0 !important;
		padding-left: 0 !important;
		@include media-breakpoint-down(lg) {
			flex: 95% !important;
			max-width: 90% !important;
			padding-right: auto !important;
			padding-left: auto !important;
		}
	}
	.item:nth-child(odd) {
		.card-image {
			transform: rotate(-4deg) translateY(-1rem);
			transition: 0.35s ease;
		}
		&:hover {
			.card-image {
				transform: rotate(0deg) translateY(-1rem);
				transition: 0.35s ease;
			}
		}
	}
	.item:nth-child(even) {
		.card-image {
			transform: rotate(4deg);
			transition: 0.35s ease;
		}
		&:hover {
			.card-image {
				transform: rotate(0deg);
				transition: 0.35s ease;
			}
		}
	}
}

section.bg-dark {
	.card {
		* {
			color: #fff!important;
		}
	}
}
