// custom-list
.custom-list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: "\f00c";
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 900;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: "\f058";
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 400;
		}
	}
}

ul.usp-list {
	display: flex;
	flex-flow: row;
	justify-content: space-around;
	li {
		display: flex;
		align-items: center;
		gap: 0.45rem;
		flex-basis: 25%;
		max-width: 30ch;
		justify-content: center;
		font-size: 16px;
		font-family: $font-family-secondary;
		color: $green-darker;
		font-weight: 600;
		&::before {
			content: "\f336";
			font-family: $font-awesome;
			font-size: 30px;
			color: $green-dark;
			font-weight: 900;
		}
	}
	@include media-breakpoint-down(lg) {
		flex-flow: column;
		li {
			width: 100%;
			max-width: unset;
			display: flex;
			justify-content: space-between;
			&::before {
				font-size: 24px;
			}
		}
	}
}
