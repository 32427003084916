// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	.owl-caption-holder {
		z-index: 200;
		display: flex;
		align-items: flex-end;
		margin-bottom: 0;
		height: 100%;
		padding-bottom: 22vh;

		@media screen and (min-width: 1024px) and (max-height: 826px) {
			padding-bottom: 16vw;
		}

		.owl-title {
			color: $white !important;
			font-size: 48px !important;
			text-shadow: 0px 0px 7px rgb(94, 94, 94);
		}
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 50vh;
	min-height: 540px;
	max-height: 1080px;
	z-index: 200;
	@include media-breakpoint-up(sm) {
		height: 80vh;
	}
	position: relative;
}

section.eyecatcher .item {
	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url("/images/6 bosrand wit.svg");
		background-position: 0 90%;
		background-repeat: no-repeat;
		z-index: 100;
	}
}

.carousel-section {
	.owl-carousel {
		margin-bottom: 6vh;
		.owl-item {
			height: 70vh;
		}
		.owl-dots {
			margin-bottom: 3vh;
		}
		.owl-title,
		.owl-subtitle {
			color: $white !important;
		}
	}
	.carousel {
		&::after {
			content: "";
			position: absolute;
			bottom: -1px;
			left: 0;
			width: 100%;
			height: 6vh;
			background: url("/images/10 boog.svg");
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: bottom;
		}
	}
	.owl-caption-holder {
		z-index: 200;
		display: flex;
		align-items: center;

		.owl-title {
			color: $white !important;
			font-size: 48px !important;
			text-shadow: 0px 0px 7px rgb(94, 94, 94);
		}
	}
}
