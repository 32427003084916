// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $green-darker;
	font-weight: 400;
}
.bg-dark,
.bg-light,
.eyecatcher {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $white;
	}
}

.width-half {
	.container-holder .column {
		max-width: 75% !important;
		@include media-breakpoint-down(lg) {
			max-width: 100% !important;
		}
	}
}

.gallery,
.gallery-horizontal {
	.large-item {
		margin-bottom: 2rem;
	}
	:nth-child(odd) {
		.gallery-link {
			border: 3px solid $white;
			transform: rotate(4deg);
			border-radius: 10px;
		}
	}
	:nth-child(even) {
		.gallery-link {
			border: 3px solid $white;
			transform: rotate(-4deg);
			border-radius: 10px;
		}
	}
	.gallery-link {
		&:hover {
			transform: rotate(0deg);
		}
	}
}

.column {
	margin-left: unset !important;
	margin-right: unset !important;
	padding-left: 2.5rem !important;
	padding-right: 2.5rem !important;
	@include media-breakpoint-down(lg) {
		padding-left: 1rem !important;
		padding-right: 1rem !important;
	}
}
