// header
.header {
	z-index: 1000;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: 0.5s;
	&.sticky {
		.logo {
			transform: scale(0.55) translateY(-1rem);
		}
	}

	> [class*="container-"] {
		.container-holder {
			> .column {
				@extend .align-items-center;
				max-height: 60px;
				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					justify-content: space-between;
				}
				@media (max-width: 290px) {
					padding: 0.5rem;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler[aria-expanded="true"] {
			.navbar-toggler-icon i {
				transform: rotate(90deg);
				transition: 0.45s ease;
				&::before {
					content: "\f00d";
				}
			}
		}
		.navbar-toggler-icon i {
			font-size: 24px;
			color: $blue-darker;
			transition: 0.45s ease;
		}
	}

	// logo

	.logo {
		width: 199px;
		margin: 2rem 10px 10px 1rem;
		transform: translateY(25%);
		transition: 0.35s ease;
		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(lg) {
			width: 140px;
			margin: 0 !important;
		}
		@media (max-width: 290px) {
			width: 75px;
			transform: translateY(0);
			margin: 0 !important;
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 1;
			background-color: white;
			margin-left: -15px !important;
			margin-right: -15px !important;
			margin-top: -3vh;
			width: 100vw;
			padding: 6vh 30px;
			border-bottom-left-radius: 14px;
			border-bottom-right-radius: 14px;
			z-index: -1;
			.navbar-nav {
				.nav-item {
					width: 100%;
				}
				.nav-link {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-weight: 600;
				}
				.dropdown-menu.show {
					position: static !important;
					padding: 0 0.5rem !important;
					border: 0;
					margin: 0 !important;
					transform: none !important;
				}
			}
		}

		@include media-breakpoint-up(xl) {
			justify-self: center;
			justify-content: center;
			.navbar-nav {
				gap: 0.5rem;
				.nav-item {
					margin: auto 0.5rem auto 0.5rem;
					.nav-link {
						color: $blue-darker;
						font-size: 16px;
						padding-left: 0 !important;
						padding-right: 0 !important;
						border-bottom: 2px solid white;
						height: 100%;
						display: flex;
						align-items: center;
						transition: 0.35s ease;
						&:hover {
							border-bottom: 2px solid $blue-darker;
							transition: 0.35s ease;
						}
					}
				}
			}
			.dropdown-menu.show {
				box-shadow: none;
				border: 0;
				padding: 1rem;
				border-radius: 10px;
				justify-content: center;
				.nav-link {
					border: none;
					font-weight: 600;
					&:hover {
						border: none;
						text-decoration: underline;
					}
				}
				&::before,
				&::after {
					display: none;
					border: none;
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-right: 1rem;
		@include media-breakpoint-down(lg) {
			margin: 0;
			max-width: 60px;
			ul {
				gap: 0;
				li {
					margin-left: 0px !important;
					margin-right: 1px;
					margin-bottom: 1px;
				}
			}
		}
	}
	.global-header-btn a {
		@extend .btn, .btn-primary;
		min-width: 112px !important;
		@include media-breakpoint-down(lg) {
			min-width: fit-content !important;
			padding: 0 !important;
			max-width: 70px !important;
		}
		@include media-breakpoint-down(xs) {
			font-size: 11px !important;
			padding: 1px !important;
		}
	}
	&::after {
		content: "";
		background-image: url("/images/9 boog.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		position: absolute;
		bottom: -6vh;
		height: 6vh;
		width: 100%;
		z-index: -1;
	}
}

// home
&.home {
	.header {
		@include media-breakpoint-up(xl) {
			position: fixed;
		}
	}
}

&.accommodation-detail .logo {
	width: 140px !important;
}
