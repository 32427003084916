// mini-sab
&.home .mini-sab .container .container-holder {
	@include media-breakpoint-up(lg) {
		position: absolute;
		left: auto;
		right: auto;
		bottom: 10vh;
		z-index: 999;
	}
	@include media-breakpoint-down(md) {
		margin-top: -20vh;
		padding-left: 30px;
		padding-right: 30px;
		z-index: 999;
	}
}
.mini-sab {
	z-index: 900;
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: 15px 0 0 0;
			padding: 15px 0;
			border-radius: 10px;
			background: $blue-dark;
			box-shadow: 3px 3px 6px rgba($black, 0.15);
			color: #fff;

			// below eyecatcher
			@include media-breakpoint-up(lg) {
				margin-top: -50px;
				padding-left: 30px;
				padding-right: 30px;
			}

			// above eyecatcher
			// @include media-breakpoint-up(lg) {
			// 	position: absolute;
			// 	left: auto;
			// 	right: auto;
			// 	bottom: 9vh;
			// 	z-index: 999;
			// }
		}
	}
	h2.ng-binding,
	label.ng-binding {
		color: $white !important;
	}
}
