// btn
.btn {
	box-shadow: $shadow;
	font-weight: 700;
	border-radius: 6px;
	padding: 0.65rem;
	font-size: 14px;
	min-width: 185px;

	// btn-primary
	&.btn-primary {
		&:hover {
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
		}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
		transition: 0.35s ease;
	}
	&:hover {
		&::before {
			transform: translateX(-0.25rem);
			transition: 0.35s ease;
		}
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
