// bg-*
// =========================================================================
&.home {
	section.bg-dark {
		position: relative;
		padding-top: 16vh;
		background-image: url("/images/6 bosrand wit.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: 0 20%;
	}
}
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
		.intro {
			padding: 6vh 0;
		}
	}
	&.bg-dark {
		position: relative;
		padding-top: 12vh;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 6vh;
			background: url("/images/7 boog.svg");
			background-repeat: no-repeat;
			background-size: 100%;
		}
	}
}

// lead-section
// =========================================================================
&.home {
	.lead-section {
		margin: 6vh 0;
	}
}
.lead-section {
	margin: 6vh 0 1rem 0;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin: 0 15px 0 0;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// usp section
.usp-section {
	padding: 6vh 0;
}

// content-section
// =========================================================================
&.home .content-section {
	&:nth-of-type(2) {
		background-image: url("/images/1 bosrand groen.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: 0 25%;
		&.bg-dark {
			background-image: url("/images/6 bosrand wit.svg");
		}
	}
}
.content-section {
	padding: 6vh 0;
	.container-holder {
		// margin-left: 0;
		// margin-right: 0;
	}
	// intro/outro
	.intro,
	.outro {
		padding-bottom: 6vh;
		.container-holder {
			max-width: 80%;
		}
		@include media-breakpoint-down(lg) {
			margin: auto;
			padding: auto;

			.container-holder {
				max-width: 100% !important;
			}
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	.home-blocks .grid-items {
		margin: 12vh auto 6vh auto !important;
		padding: 0 1.5rem;
		@include media-breakpoint-down(lg) {
			padding: 1rem;
			gap: 3vh;
		}
		.item {
			padding: 0 !important;
			flex-basis: 50% !important;
			max-width: 45% !important;
			.card-image {
				overflow: visible;
				margin-bottom: 3vh;
			}
			.card-image img {
				border-radius: 40px;
			}
			&:nth-child(odd) {
				.card-image img {
					transform: rotate(-4deg);
					&:hover {
						transform: rotate(0deg);
					}
				}
			}
			&:nth-child(even) {
				.card-image img {
					transform: rotate(4deg);
					&:hover {
						transform: rotate(0deg);
					}
				}
			}
			.card {
				overflow: visible;
				border: 0;
				background: none;
				.card-body {
					padding: 0 3.5rem 0 3.5rem;
				}
				.card-btn {
					@extend .btn, .btn-primary;
				}
				.card-subtitle {
					display: none;
				}
			}
			@include media-breakpoint-down(lg) {
				flex-basis: 100% !important;
				max-width: 100% !important;
				.card .card-body {
					padding: unset !important;
				}
			}
		}
	}
	.slider-container {
		max-width: 75%;
		@include media-breakpoint-down(lg) {
			max-width: 100%;
			padding-left: 0 !important;
			padding-right: 0 !important;
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
		.container-holder,
		.container-holder .column {
			@include media-breakpoint-down(lg) {
				padding-left: 0 !important;
				padding-right: 0 !important;
				margin-left: 0 !important;
				margin-right: 0 !important;
			}
		}
		.slider {
			.owl-nav {
				display: none !important;
			}
			.owl-stage-outer {
				padding-top: 12vh;
				overflow: visible;
				@include media-breakpoint-down(lg) {
					overflow: hidden;
				}
			}
			.owl-item {
				&:nth-child(odd) {
					.card-image img {
						transform: rotate(-4deg);

						&:hover {
							transform: rotate(0deg) scale(1.05);
							box-shadow: 0 0 10px rgb(58, 58, 58);
						}
					}
				}

				&:nth-child(even) {
					.card-image img {
						transform: rotate(4deg) translateY(-2rem);

						&:hover {
							transform: rotate(0deg) scale(1.05)
								translateY(-2rem);
							box-shadow: 0 0 10px rgb(58, 58, 58);
						}
					}
				}
				.item {
					padding: 0 !important;
					z-index: 800;
					.card-image {
						overflow: visible;
						margin-bottom: 3vh;
					}

					.card-image img {
						border-radius: 40px;
						border: 3px solid $white;
					}

					.card {
						overflow: visible;
						border: 0;
						background: none;

						.card-body {
							display: flex;
							justify-content: center;
							align-items: center;
							> div {
								width: 80%;
							}

							* {
								color: $white;
							}
							.card-title {
								font-size: 20px !important;
							}
							.card-btn {
								background: none;
								min-width: auto;
								padding: 0;
								border: 0;
								text-decoration: underline;
							}
						}
					}
					&:hover {
						z-index: 990 !important;
					}
				}
			}
		}
	}
	.hero {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
		margin: 6vh auto;
		border: 1px solid $white;
		border-radius: 10px;
		.btn {
			margin-right: 0.5rem;
		}
		@include media-breakpoint-down(lg) {
			max-width: 90%;

			.btn {
				margin-top: 0.5rem;
				margin-right: unset !important;
			}
		}
	}
	.faq {
		h4,
		h5,
		h6 {
			color: $black;
			font-weight: 500;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;
	background-image: url("/images/1 bosrand groen.svg");
	background-repeat: no-repeat;
	background-size: 100%;

	// collection
	.collection {
		.card {
			.card-image,
			.card-image img {
				border-radius: 12px !important;
				border: 2px solid $white;
			}
		}
	}
	.container-holder {
		padding: 0 5rem;
		@include media-breakpoint-down(lg) {
			padding: unset !important;
		}
	}
}

&.accommodation-category-overview {
	.bundle-overview-section {
		.collection {
			.grid-items {
				gap: 1.5rem;
				.item {
					@include media-breakpoint-down(lg) {
						flex-basis: 100% !important;
						max-width: 95% !important;
					}
					flex-basis: 33% !important;
					max-width: 33% !important;
					.card {
						.card-image {
							transform: none;
						}
						.card-title {
							font-size: 30px;
							color: $green-darkest;
							font-weight: 300;
						}
						.card-subtitle {
							display: none;
						}
					}
				}
			}
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	// background-image: url("/images/1 bosrand groen.svg");
	// background-repeat: no-repeat;
	// background-size: 100%;

	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	background-image: url("/images/1 bosrand groen.svg");
	background-repeat: no-repeat;
	background-size: 100%;

	margin: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
