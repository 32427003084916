// footer
.footer {
	section {
		padding: 3vh 0;
	}
	.footer-intro {
		background-image: url("/images/1 bosrand groen.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: center;
		.container {
			@include media-breakpoint-down(lg) {
			}
			display: flex;
			justify-content: center;

			.container-holder {
				background: $blue-dark;
				padding-top: 2.5rem;
				padding-bottom: 2.5rem;
				min-width: 100%;
				border-radius: 10px;
				.one,
				.two {
					* {
						color: $white !important;
					}
				}
				.one {
					flex-basis: 80%;
					max-width: 80%;
				}
				.two {
					flex-basis: 20%;
				}
				@include media-breakpoint-down(lg) {
					min-width: 95%;
					max-width: 95%;
				}
			}
			@include media-breakpoint-down(lg) {
				width: 100%;
				.container-holder {
					padding: 2.5rem 0.35rem;
					.one,
					.two {
						flex-basis: 100%;
						max-width: 100%;
						.btn {
							margin-top: 0.35rem;
						}
					}
				}
			}
			div.footer-btn {
				ul.footer-btn {
					display: flex;
					gap: 0.65rem;
					flex-flow: row wrap;
					padding-bottom: 0.5rem;
					li {
						a {
							@extend .btn, .btn-primary;
							min-width: auto !important;
							width: fit-content !important;
							display: flex;
							gap: 0.5rem;
							align-items: center;
							justify-content: flex-start;
							i {
								font-weight: 900;
							}
						}
					}
				}
			}
		}
		div.socialmedia-icons {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			height: 100%;
			ul.socialmedia-icons {
				li {
					margin-left: 0.55rem;
					transition: 0.35s ease;
					i {
						font-size: 32px;
					}
					.list-item-title {
						display: none;
					}
					&:hover {
						transform: rotate(20deg);
						transition: 0.35s ease;
					}
				}
			}
			@include media-breakpoint-down(lg) {
				justify-content: flex-start;
			}
		}
	}
	.footer-logos {
		ul.partner-logos {
			justify-content: center;
			gap: 1.5rem;
			.list-item-title {
				display: none;
			}
			li {
				filter: grayscale(0.3);
				opacity: 0.85;
				display: flex;
				align-items: center;
				transition: 0.35s ease;
				&:hover {
					filter: grayscale(0);
					opacity: 1;
					transition: 0.35s ease;
				}
				img {
					max-width: 120px;
				}
			}
		}
	}
	.footer-socket {
		.list {
			justify-content: center;
			gap: 0.85rem;
			a {
				color: $blue-dark;
			}
		}
	}
}
